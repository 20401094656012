.deposit-tracking-col-name {
    background-color: $bleu-fonce1-futu !important;
    color: $blanc-futu !important;
}

.table {
    .col-width-10 {
        width: 10%;
    }
    .col-width-15 {
        width: 15%;
    }
    .col-width-30 {
        width: 30%;
    } 
}

.btn-refresh-deposit-tracking {
    background-color: $bleu-fonce1-futu !important;
    color: $blanc-futu !important;
    border-color: $bleu-fonce1-futu !important;
}

.clicked {
    animation: shake 0.3s;
  }
  
  @keyframes shake {
    10%, 90% {
      transform: translateX(-0.25px);
    }
    20%, 80% {
      transform: translateX(0.75px);
    }
    30%, 50%, 70% {
      transform: translateX(-1.5px);
    }
    40%, 60% {
      transform: translateX(1.5px);
    }
  }

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30vh; /* Ajustez la hauteur selon vos besoins */
}

.loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: $bleu-fonce1-futu; /* Changez la couleur selon vos préférences */
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
  