// Colors

$bleu-fonce2-futu : #0D2753;
$bleu-fonce1-futu : #032B7C;
$bleu-futu : #2278f3;
$bleu-clair-futu : #45A7EE;

$rouge-futu : #e24b4b;

$gris-futu : #515151;
$gris-shadow : #ccc;

$vert-futu : #32BEA6;

$blanc-futu : #FFFFFF;
