.amplify-button--primary {
    background-color: $bleu-fonce1-futu;
    &:hover {
        background-color: $bleu-futu;
    }
}
.amplify-button--link {
    color: $bleu-fonce1-futu;
    &:hover {
        background-color: #fff;
        color: $bleu-futu;
    }
}

.amplify-tabs-item[data-state=active] {
    border-color: $bleu-fonce1-futu;
    color: $bleu-fonce1-futu;
    &:hover {
        border-color: $bleu-fonce1-futu;
        color: $bleu-fonce1-futu; 
    }
}

.amplify-tabs-item[data-state=inactive] {
    border-color: #fff;
    color: $bleu-fonce2-futu;
    &:hover {
        border-color: #fff;
        color: $bleu-fonce1-futu; 
    }
}
.amplify-field-group__outer-end {
    .amplify-field__show-password{
        &:hover {
            border-color: grey;
            background-color: #fff;
        }
        .amplify-icon {
            &:hover {
               color: #000;
            }
        }
    }    
}

.connexion-block {
    background-color: $bleu-fonce1-futu;
    padding: 12px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    h2 {
        font-size: 1.3rem;
        color: #fff;
        margin-bottom: 5px;
    }
}