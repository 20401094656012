header {
  height: 10vh; // 9.53

  .title-header {
    font-size: 2.8rem;
    color: $bleu-fonce1-futu;
    font-weight: bold;
    text-transform: uppercase;
  }

  .language-select {
    display: flex;
    align-items: center;
    margin-right: 1rem;
  }
  
  .custom-dropdown-item {
    display: flex;
    justify-content: center;
    cursor: pointer;
  }
  
  .custom-dropdown-item:hover {
    background-color: #ccc;
  }
}