.home {
    .btn-element {
        list-style-type: none;
        margin-top: 4rem;
    }
    button {
        font-size: 1rem;
        height: 3rem;
        min-height: 2.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.5rem 1rem;

        // Empêcher le texte de passer sur plusieurs lignes
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
} 

.button-container {
    display: flex;
    justify-content: space-around;
    gap: 2rem;

    // Pour les écrans de taille moyenne et petite
    @media screen and (max-width: 1024px) {
        flex-direction: column;
        gap: 3rem;
    }

    .column {
        flex: 1;
        position: relative;

        // Ajoute une ligne verticale après chaque colonne sauf la dernière
        &:not(:last-child)::after {
            content: '';
            position: absolute;
            right: -1rem;
            top: 0;
            height: 100%;
            width: 1px;
            background-color: #ccc;
        }
        
        @media screen and (max-width: 1024px) {
            &:not(:last-child)::after {
                left: 0;
                right: 0;
                top: auto;
                bottom: -1rem;
                height: 1px;
                width: 100%;
            }
        }
        
        h3 {
            text-align: center;
            margin-bottom: 1rem;
            font-size: 1.5rem;
            font-weight: bold;
            color: $bleu-fonce1-futu;

            @media screen and (max-width: 1024px) {
                font-size: 1.3rem;
            }
        }

        .btn-list {
            list-style: none;
            padding: 0;
            margin: 0;

            .btn-element {
                margin-bottom: 1rem;
                
                &:last-child {
                    margin-bottom: 0;
                }

                .button-large {
                    width: 100%;
                    max-width: 300px;
                    margin: 0 auto;
                    display: block;
                    padding: 0.8rem 1.5rem;

                    @media screen and (max-width: 768px) {
                        font-size: 0.9rem;
                        padding: 0.6rem 1.2rem;
                    }

                    @media screen and (max-width: 480px) {
                        font-size: 0.8rem;
                        padding: 0.5rem 1rem;
                    }
                }
            }
        }
    }
}

.text-center {
    text-align: center;
}