footer {
    width: 100%;
    height: 14.5vh; // 13.82
    bottom: 0px; 
    left: 0; 
    right: 0;

    font-size: 1rem;

    background-color: $bleu-fonce2-futu;
    color: $blanc-futu;

    .icone_svg {
        height: 2rem;
        width: auto;
    }

    .svg_blanc {

        filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
    
        &:hover {
            cursor: pointer;
            border: 2px solid $blanc-futu;
            border-radius: 2rem;
            padding: 2px;
            margin: -2px
        }
    }
    .image_footer{
        height: 3.75rem;
        width: auto;
    }
    .telephone_number {
        color: $blanc-futu;
        font-weight: bold;
        font-size: 1rem;
        line-height: 2rem;
        cursor: pointer
      }
      
}