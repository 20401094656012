.button-large {
    padding: 1rem 1.5rem;
    border-radius: 0.8rem;
    line-height: 1.5;
    font-weight: 500;
    text-transform: uppercase;
    text-decoration: none;
    background-color: $bleu-fonce1-futu;
    color: $blanc-futu;
    transition: all 0.3s ease-in-out;
    box-shadow: 5px 2.5px 2.5px $gris-shadow;
    border: 2px solid $bleu-fonce2-futu;
    &:hover{
        background-color: $blanc-futu;
        color: $bleu-fonce1-futu;
    }
}

.button-mid {
    font-size: 0.75rem;
    padding: 0.9rem 1.2rem;
    border-radius: 0.8rem;
    line-height: 1.2;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;
    background-color: $bleu-fonce1-futu;
    color: $blanc-futu;
    transition: all 0.3s ease-in-out;
    box-shadow: 5px 2.5px 2.5px $gris-shadow;
    border: 2px solid $bleu-fonce1-futu;
    &:hover {
        background-color: $blanc-futu;
        color: $bleu-fonce1-futu;
      }
}

.button-small-back {
    font-size: 0.65rem;
    margin-top: 1rem;
    margin-left: 1rem;
    padding: 0.6rem 1rem;
    border-radius: 0.8rem;
    line-height: 1.2;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;
    background-color: $bleu-fonce1-futu;
    color: $blanc-futu;
    transition: all 0.3s ease-in-out;
    box-shadow: 5px 2.5px 2.5px $gris-shadow;
    border: 2px solid $bleu-fonce1-futu;
    &:hover {
        background-color: $blanc-futu;
        color: $bleu-fonce1-futu;
      }
}