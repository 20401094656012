.extension_button { 
    display: flex;
}
.file_extension_button {
    border: 1px solid black;
    margin: 5px;
    padding: 10px;
    cursor: pointer;
  }
  
  .file_extension_button:hover {
    background-color: #ddd;
  }

#file_repository_create_format {
  width: 100% !important;
}

button[type="submit"] {
  border: none;
  border-radius: 0.25rem;
  background-color: $bleu-fonce1-futu;
  cursor: pointer;
  color: $blanc-futu;
  box-shadow: 5px 2.5px 2.5px $gris-shadow;
      &:hover {
          background-color: $bleu-futu; 
          color: #fff;
      }
}

.th_table_custom {
  background-color: $bleu-fonce1-futu !important;
  color: #fff !important;
}

.flex_custom {
  flex: auto; 
  font-size: 1.1rem;
  display: flex;
  flex-direction: column;
}

.title_mapping_span_custom {
  color: $gris-futu;
  font-size: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between; 
}


.title_mapping_span_custom {
  color: $gris-futu;
  font-size: 1.5rem;
}

.boxed-create-format {
  width: 85%;
}

.select_custom_mapping_file {
  width: 80% !important;
  border: 1px solid $bleu-fonce1-futu !important;
}

.border_mapping_file {
  border: 1px solid $gris-futu;
  border-radius: 8px;
  margin-bottom: 2rem;
}

.value_mapping_span_custom{
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: space-between !important; 
}

.form-control-custom {
  margin: auto !important;
  width: 66% !important;
}

.thead-mapping-file-custom {
  font-size: 1.3;
}


.headers-table {
  font-weight: bold;
}

.table-mapping-file-custom {
  thead {
    font-size: 1.3rem !important;
    tr {
      th {
        color: $gris-futu
      }
    }
  }
  thead, td {
      white-space: nowrap !important;
  }
} 

.table {
  .col-width-5 {
      width: 5%;
  }
  .col-width-10 {
      width: 10%;
  }
  .col-width-15 {
      width: 15%;
  }
  .col-width-20 {
      width: 20%;
  }
  .col-width-25 {
      width: 25%;
  } 
  .col-width-30 {
      width: 30%;
  } 
}

.btn-delete-custom-mapping-file {
  padding: 2px 2px !important;
  color: red !important;
  background-color: #fff !important;
  border-color: #fff !important;
}