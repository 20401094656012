.error-management {
    .error-color {
        color: $bleu-fonce1-futu;
    }
    h1 {
        font-size: 10rem;
    }
    h2 {
        font-size: 3rem;
    }
    p {
        font-size: 2rem;
        margin: 0 0 2rem;
    }
}