.boxed {
    width: 25%;
}

.boxed-create {
    width: 70%;
}

.form-style {
    border: 1px solid $gris-futu;
    form {
        font-weight: bold;
    }

    select {
        border: 2px solid $bleu-fonce1-futu;
        border-radius: 4px;
        box-shadow: 5px 2.5px 2.5px $gris-shadow;
            &:focus {
                border: 2px solid $bleu-futu;
            }
    }

    input[type="text"] {
    border: 2px solid $bleu-fonce1-futu;
    border-radius: 4px;
    box-shadow: 5px 2.5px 2.5px $gris-shadow;
        &:focus {
            border: 2px solid $bleu-futu;
        }
    }
    
    input[type="number"] {
        border: 2px solid $bleu-fonce1-futu;
        border-radius: 4px;
        box-shadow: 5px 2.5px 2.5px $gris-shadow;
            &:focus {
                border: 2px solid $bleu-futu;
            }
        }     

    button[type="submit"] {
        border: none;
        border-radius: 0.25rem;
        background-color: $bleu-fonce1-futu;
        cursor: pointer;
        color: $blanc-futu;
        box-shadow: 5px 2.5px 2.5px $gris-shadow;
            &:hover {
                background-color: $bleu-futu; 
            }
    }

    button[type="button"] {
        border: none;
        border-radius: 0.25rem;
        background-color: $bleu-fonce1-futu;
        cursor: pointer;
        color: $blanc-futu;
        box-shadow: 5px 2.5px 2.5px $gris-shadow;
            &:hover {
                background-color: $bleu-futu; 
            }
    }

    .btn-delete {
        background-color: $rouge-futu !important;
    }

    input[type="password"] {
        border: 2px solid $bleu-fonce1-futu;
        border-radius: 4px;
        box-shadow: 5px 2.5px 2.5px $gris-shadow;
            &:focus {
                border: 2px solid $bleu-futu;
            }
    }   

    .input-small {
        max-width: 30vh;
        margin: auto;
    }
    
    .input-file-repository {
        max-width: 50vh;
        margin: auto;
    }

    .button-catalog {
        padding: 6px 7px;
    }

    .button-next-previous {
        color: $bleu-fonce1-futu;
        text-decoration: none ;
    }
}

.submit_message, .error_message {
    margin-top: 1rem;
}

.alert-message{
    width: 50%;
}

.table-reference-custom {
    .th-custom {
        background-color: $bleu-fonce1-futu;
        color: #fff;
    }
    thead, td {
        white-space: nowrap;
    }
} 

.color-icon-table{
    background-color: $bleu-fonce1-futu;
    color: #fff
}